<template>
  <!-- prettier-ignore -->
  <div class="navigation">
    <div class="container">
      <template
        v-for="(item, index) in items"
        :key="item.name"
      >

        <div
          v-if="index > 0"
          class="line"
          :class="getClasses(item, index, 'line')"
        />

        <router-link
          class="step"
          :class="getClasses(item, index, 'step')"
          :to="!item.disabled ? item.path : ''"
        >
          <div class="step__icon">
            <the-icon
              :art="item.disabled ? 'light' : 'solid'"
              name="circle"
            />
          </div>
          <div
            class="step__nr body-3"
            v-text="index + 1"
          />
          <div
            class="step__text title-3"
            v-text="$t(`navigation.${item.name}`)"
          />
        </router-link>
      </template>
    </div>
  </div>
</template>

<script setup>
/**
 * FIGMA:
 * NOTES:
 * KNOWN ISSUES:
 *  - active navpoint uses more space -> leads to jumping
 */
import { computed } from 'vue'
import { useRoute } from 'vue-router'

import { getRoutes } from '@/helpers/Route'

import basketStore from '@/store/basket'

// HOOKS
const route = useRoute()

// COMPUTED
const items = computed(() => {
  // if offer already submitted => abort
  if (basketStore.basket.submitted) return []

  return getRoutes(route, basketStore.basket).filter(__route => !__route.hidden && __route.isInNavigation)
})

// const showNavigation = computed(() => {
//   return route.meta?.navigation === undefined || route.meta?.navigation !== false
// })

const states = computed(() => {
  return items.value.map((_r, index) => {
    const prev = items.value[index - 1]
    const next = items.value[index + 1]

    return {
      prevComplete: prev?.complete ?? false,
      nextComplete: next?.complete ?? false,
    }
  })
})

// METHODS
function getClasses(__route, index, prefix) {
  return {
    [`${prefix}--active`]: !!__route.match,
    [`${prefix}--complete`]: !!__route.complete,
    [`${prefix}--disabled`]: !!__route.disabled,
    [`${prefix}--invalid`]: !!__route.invalid && !__route.disabled,

    [`${prefix}--prev-complete`]: !!states.value[index].prevComplete,
    [`${prefix}--next-complete`]: !!states.value[index].nextComplete,
  }
}
</script>

<style scoped>
.navigation {
  .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    height: 60px;
  }

  /** all transitions **/
  .step {
    .step__icon {
      transition-property: font-size, color;
      transition-duration: 150ms;
      transition-timing: linear;
    }

    .step__text {
      transition-property: top, font-weight;
      transition-duration: 150ms;
      transition-timing: linear;
      transition-delay: 100ms;
    }
  }

  /** styling for a step (icon, number, text) **/
  .step {
    flex: 0 0 auto;

    position: relative;
    display: flex;
    flex-direction: column;
    text-align: center;

    &__icon {
      flex: 0 0 40px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      z-index: var(--dvp-stack-level-element);
      color: var(--tertiary);
      font-size: 22px;
    }

    &__nr {
      display: none;
      z-index: var(--dvp-stack-level-element);
    }

    &__text {
      flex: 0 0 20px;
      white-space: nowrap;
      font-size: 0;
    }

    &:not(:last-child):after {
      position: absolute;
      content: '';
      height: 2px;
      top: 19px;
      left: calc(50% + 10px);
      right: 0;

      background-color: var(--colors-neutral-a-700);
    }

    &:not(:first-child):before {
      position: absolute;
      content: '';
      height: 2px;
      top: 19px;
      left: 0;
      right: calc(50% + 10px);

      background-color: var(--colors-neutral-a-700);
    }

    &.step--active {
      .step__icon {
        color: var(--surface);
        font-size: 32px;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.12));
      }

      .step__nr {
        display: initial;
        position: absolute;
        left: calc(50% - 16px);
        top: calc(50% - 20px);
        text-align: center;
        width: 32px;
      }

      .step__text {
        font-weight: var(--font-weight-bold);
        font-size: var(--font-size-bold-sm);
        letter-spacing: var(--letter-spacing-bold-sm);
      }
    }

    &.step--disabled {
      cursor: default;
      pointer-events: none;

      .step__icon {
        color: var(--colors-neutral-a-700);
      }

      .step__text {
        color: var(--Interaction-States-disabled-default-label);
      }
    }

    &.step--complete {
      &:before {
        height: 4px;
        top: 18px;
        right: calc(50% + 10px);
        background-color: var(--tertiary);
      }

      &.step--next-complete:after {
        height: 4px;
        top: 18px;
        left: calc(50% + 10px);
        background-color: var(--tertiary);
      }
    }
  }

  /** styling for the lines between steps **/
  .line {
    flex: 1 0 auto;
    position: relative;

    &:after {
      position: absolute;
      content: '';
      height: 2px;
      top: 19px;
      width: 100%;
      background-color: var(--colors-neutral-a-700);
    }

    &.line--complete {
      &:after {
        height: 4px;
        top: 18px;
        background-color: var(--tertiary);
      }
    }
  }
}

@media (--v-medium) {
  .navigation {
    .container {
      height: 72px;
    }

    .step {
      &__text {
        font-weight: var(--font-weight-regular);
        font-size: var(--font-size-regular-sm);
        letter-spacing: var(--letter-spacing-regular-sm);
      }

      &.step--active {
        gap: var(--spacing-san-spacing-03);

        .step__icon {
          font-size: 40px;
        }

        .step__nr {
          top: calc(50% - 25px);
        }
      }
    }
  }
}

@media (--v-large) {
}

@media (--v-wide) {
  .navigation {
    .container {
      height: 80px;
    }

    .step {
      &__text {
        font-weight: var(--font-weight-regular);
        font-size: var(--font-size-regular-md);
        letter-spacing: var(--letter-spacing-regular-md);
      }

      &.step--active {
        gap: var(--spacing-san-spacing-04);

        .step__nr {
          top: calc(50% - 29px);
        }

        .step__text {
          font-weight: var(--font-weight-bold);
          font-size: var(--font-size-bold-md);
          letter-spacing: var(--letter-spacing-bold-md);
        }
      }
    }
  }
}

@media (--v-ultra) {
}

@media (--v-max) {
  .navigation {
    .container {
      height: 96px;
    }

    .step {
      &.step--active {
        gap: var(--spacing-san-spacing-06);

        .step__icon {
          font-size: 48px;
        }

        .step__nr {
          top: calc(50% - 34px);
        }
      }
    }
  }
}
</style>
