<template>
  <div
    class="ovp-configurator ovp-a11y-container"
    :app-language="selectedLanguage"
    :app-name="$props.source"
    :app-version="version$"
  >
    <template v-if="isApplicationReady">
      <system-notifications
        :key="`notifications-${selectedLanguage}`"
        class="section__alerts"
        app="dvp"
        :base-url="ressourceURI"
        :language="selectedLanguage"
      />

      <div class="section__header">
        <the-header :meta-options="routeMeta">
          <template #navigation>
            <the-navigation />
          </template>
        </the-header>
      </div>

      <div class="section__content">
        <router-view v-slot="{ Component }">
          <transition>
            <div :key="Component?.type.__name || route.name">
              <component :is="Component" />
            </div>
          </transition>
        </router-view>
      </div>

      <div class="section__footer">
        <contact-block />
        <save-nudge v-if="isNudgingEnabled" />
      </div>

      <div id="modals"></div>
      <medical-office-search />
      <the-confirmation />
      <the-appointment />
      <fab-container />
      <screen-auth />
      <product-replace-modal />
      <product-suggestion-modal />
    </template>

    <the-loading />
    <global-error-handler />
    <!--<quiz-modal />-->
    <the-survey />
    <the-snackbar />
  </div>
</template>

<script setup>
import { computed, inject, nextTick, onBeforeMount, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { registerBasketActionListeners, registerUpdateBasketListeners } from '@/helpers/GlobalEventHandler'
import { logInfo } from '@/helpers/Logger'
import { events$, loading$ } from '@/services'

import basketStore from '@/store/basket'
import useApplication from '@/hooks/useApplication'
import useI18n from '@/hooks/useI18n'
import useSurvey from '@/hooks/useSurvey'
import useSync from '@/hooks/useSync'
import useUrl from '@/hooks/useUrl'

import ProductReplaceModal from '@/components/Product/Replace/ReplaceModal'
import ProductSuggestionModal from '@/components/Product/Replace/SuggestionModal'
import FabContainer from '@/components/Fab/FabContainer'
import GlobalErrorHandler from '@/components/Container/GlobalErrorHandler'
import MedicalOfficeSearch from '@/components/MedicalSearch/MedicalOfficeSearch'
//import QuizModal from '@/components/Quiz/QuizModal'
import SaveNudge from '@/components/Basket/SaveNudge/SaveNudge'
import ScreenAuth from '@/components/Screen/ScreenAuth'
import SystemNotifications from '@/components/Container/SystemNotifications'
import TheAppointment from '@/components/Modal/Appointment'
import TheConfirmation from '@/components/Modal/Confirmation'
import ContactBlock from '@/components/Container/ContactBlock'
import TheHeader from '@/components/Header/Header'
import TheLoading from '@/components/Container/Loading'
import TheNavigation from '@/components/Navigation/Navigation'
import TheSurvey from '@/components/Survey/Survey'

import { EVENT_DOTCOM_SURVEY_WEBSITE, EVENT_URL, SOURCE } from '@/config/events'
import { ROUTE_NAME, SURVEY_TOUCHPOINT_WEBSITE } from '@/config/constants'

import '@/assets/css/import.css'

// HOOKS
const { initializeClearSequence, isReadyWithoutPartner, isReadyWithPartner } = useApplication()
const { selectedLanguage } = useI18n()
const route = useRoute()
const router = useRouter()
const { triggerSurvey } = useSurvey()
const { registerSync, unregisterSync } = useSync()
const { react } = useUrl()

// INIT
const ressourceURI = import.meta.env.VITE_SANITAS_RESOURCE_URI
const version$ = inject('version$')
defineProps({
  source: {
    type: String,
    default: SOURCE.CONFIGURATOR,
  },
})

// DATA
const pristine = ref(true)
const routeTransition = ref('slide-left')
let routeConfirmed = ref(false)

// COMPUTED
const isApplicationReady = computed(() => {
  if (routeMeta.value.isReentry) {
    return isReadyWithoutPartner.value && routeConfirmed.value
  } else {
    return isReadyWithPartner.value && routeConfirmed.value
  }
})

const isNudgingEnabled = computed(() => {
  return basketStore.basket.persons?.length > 0 && !basketStore.basket.existingCustomer
})

const routeMeta = computed(() => route?.meta || {})

// const showNavigation = computed(() => route.name && !route.meta.aide)

// METHODS
function emitEventWhenReady() {
  return new Promise(function (resolve) {
    if (isApplicationReady.value) resolve()

    const unwatch = watch(
      () => isApplicationReady.value,
      () => {
        unwatch()
        resolve()
      }
    )
  })
}

/**
 * Check, if the form has been touched, when unload is triggered
 * (so you don't loose unsaved changes)
 */
function unload(event) {
  const disabled = route.meta?.disableUnloadConfirmation || pristine.value
  if (disabled) return true

  const message = 'nothing'
  event.returnValue = message
  return message
}

// WATCHERS
watch(isApplicationReady, async value => {
  if (value) {
    logInfo(['%cLOCKED AND LOADED!', 'color: GREEN'])

    await nextTick()

    loading$.end()
  }
})

// LIFECYCLE HOOKS
onBeforeMount(() => {
  initializeClearSequence()

  loading$.start()

  window.addEventListener('beforeunload', unload)

  events$.once(EVENT_DOTCOM_SURVEY_WEBSITE, () => {
    triggerSurvey(SURVEY_TOUCHPOINT_WEBSITE)
  })
})

onMounted(() => {
  registerUpdateBasketListeners()
  registerBasketActionListeners()

  emitEventWhenReady().then(() => {
    // tab-sync needs to be registered, after all the BE-/localStorage operations are done
    registerSync()

    // react to URL-params (e.g. open login)
    react()
  })

  events$.on(EVENT_URL.GOTO, opts => {
    if (!opts.type) opts.type = 'push'

    // new route can come as a string, or as a route-object
    if (opts.target) {
      if (basketStore.basket.persons.length > 0) {
        router[opts.type](opts.target.replace(':personId', basketStore.basket.persons[0].personId))
      }
    } else {
      router[opts.type](opts.route)
    }
  })

  document.addEventListener('keydown', event => {
    // tab, left, up, right, down
    if ([9, 37, 38, 39, 40].includes(event.keyCode)) {
      window.setTimeout(() => {
        if (document.activeElement?.classList && document.activeElement.classList.contains('outline')) {
          document.activeElement.classList.add('has-outline')
        }
      }, 10)
    }
  })
})

onBeforeUnmount(() => {
  unregisterSync()
  window.removeEventListener('beforeunload', unload)
})

router.beforeEach((to, from) => {
  const __start = from.meta.index
  const __end = to.meta.index

  // from ovp to alva
  if (__start < 100 && __end >= 100) {
    routeTransition.value = 'slide-down'
    // from alva to ovp
  } else if (__start >= 100 && __end < 100) {
    routeTransition.value = 'slide-up'
    // normal
  } else {
    // @TODO: Figure out, why the transition is wonky, when navigating to onboarding-screen
    if (to.name === ROUTE_NAME.ONBOARDING) {
      routeTransition.value = null
    } else {
      routeTransition.value = to.meta.index < from.meta.index ? 'slide-right' : 'slide-left'
    }
  }

  return true
})

router.isReady().then(() => {
  routeConfirmed.value = true
})
</script>

<style name="global">
:root {
  /*
    New stacking contexts can be formed on an element in one of three ways:
    - When an element is the root element of a document (the <html> element)
    - When an element has a position value other than static and a z-index value other than auto
    - When an element has an opacity value less than 1
  */
  --dvp-stack-level-element: 1;
  --dvp-stack-level-popup-menu: 2;
  --dvp-stack-level-modal: 3;
  --dvp-stack-level-modal-stacked: 4; /* exception for medical-office-search modal as it can be opened within another modal */
  --dvp-stack-level-popup-notification: 5;
  --dvp-stack-level-tooltip: 6;
  --dvp-stack-level-system-notification: 7;
  --dvp-stack-level-screen-blocker: 8;
}

#chat-ui {
  position: relative;
  z-index: var(--dvp-stack-level-popup-menu);
}

*,
*:after,
*:before {
  box-sizing: border-box;
}

*:focus {
  outline: none;
}

html {
  overflow-y: scroll;
}

body {
  font-family: var(--font-family-open-sans);
  font-style: var(--font-style-default);
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-regular-lg);
  letter-spacing: var(--letter-spacing-regular-lg);
  color: var(--Text-Primary);

  margin: 0;
  /* FLEX-2954 prevent larger fonts for mobile landscape */
  -webkit-text-size-adjust: none;
}

p,
h1,
h2,
h3 {
  margin-block-start: 0;
  margin-block-end: 0;
}

a {
  color: inherit;
  text-decoration-line: none;
}

button,
input,
select,
textarea {
  font-family:
    Open Sans,
    sans-serif !important;
}

.tippy-box[data-theme~='dvp'] {
  min-width: 144px;
  padding: var(--fixed-spacing-fix-06);
  background-color: var(--surface);
  border-radius: var(--fixed-border-radius-fix-02);
  box-shadow: var(--elevation-level-5);

  .tippy-content {
    font-weight: var(--font-weight-regular);
    font-size: var(--font-size-regular-sm);
    letter-spacing: var(--letter-spacing-regular-sm);
    color: var(--on-surface);
    padding: 0;
  }

  .tippy-arrow {
    color: var(--surface);

    &::before {
      transform: scale(1.25);
    }
  }
}

.tippy-box[data-theme~='card'] {
  background-color: var(--surface);
  border-radius: var(--fixed-border-radius-fix-02);
  box-shadow: var(--elevation-level-5);
  padding: var(--fixed-spacing-fix-02);
  margin: 0;
  & > .tippy-content {
    padding: 0;
    margin: 0;
    height: max-content;

    & > .card__image {
      margin: 0;
      padding: 0;
      width: 100%;
    }
  }

  .tippy-arrow {
    color: var(--surface);

    &::before {
      transform: scale(1.25);
    }
  }
}

.tippy-box[data-placement='top-end'] > {
  .tippy-arrow {
    right: 20px !important;
    left: initial !important;
  }
}

.the-transition-enter-active,
.the-transition-leave-active {
  transition: opacity 0.3s ease-in-out;
}

.the-transition-enter-from,
.the-transition-leave-to {
  opacity: 0;
}
</style>

<style name="animations" scoped>
.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active,
.slide-up-enter-active,
.slide-up-leave-active,
.slide-down-enter-active,
.slide-down-leave-active {
  overflow: hidden;
  transition-duration: 0.5s;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
}

.slide-left-enter-from,
.slide-right-leave-to {
  transform: translate(100%, 0);
}
.slide-left-leave-to,
.slide-right-enter-from {
  transform: translate(-100%, 0);
}

.slide-up-enter-from,
.slide-down-leave-to {
  transform: translate(0, 100%);
}
.slide-up-leave-to,
.slide-down-enter-from {
  transform: translate(0, -100%);
}
</style>

<style scoped>
.ovp-configurator {
  display: grid;
  grid-template-areas:
    'alerts'
    'header'
    'content'
    'footer';
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: auto auto 1fr auto;
  height: 100vh;

  .section {
    &__alerts {
      grid-area: alerts;
    }

    &__header {
      grid-area: header;
      background-color: var(--surface-ext-b-low);
    }

    &__content {
      grid-area: content;
    }

    &__footer {
      grid-area: footer;
      background-color: var(--surface-ext-b-low);
      border-radius: var(--border-radius-res-l) var(--border-radius-res-l) 0 0;
    }
  }
}
</style>
