<template>
  <!-- prettier-ignore -->
  <div/>
</template>

<script setup>
import { getCurrentInstance } from 'vue'

import { logError } from '@/helpers/Logger'

import useI18n from '@/hooks/useI18n'
import useSnackbar from '@/hooks/useSnackbar'

import { NOTIFICATION } from '@/config/constants'

// HOOKS
const { t } = useI18n()
const { addNotification } = useSnackbar()

// INIT
const app = getCurrentInstance()
app.appContext.config.errorHandler = err => {
  handleError(err)
}
window.onerror = function (message, _source, _lineno, _colno, error) {
  console.log(message, _source, _lineno, _colno, error)

  if (error) {
    logError(['GLOBAL ERROR -> ', error.stack])
  } else {
    logError(['GLOBAL MESSAGE ->', message])
  }
}

// METHODS
function handleError(e) {
  logError(['VUE ERROR -> ', e])
  addNotification({
    text: t('error.GENERAL'),
    type: NOTIFICATION.ERROR,
  })

  // report error to Instana
  if (window.ineum) {
    window.ineum('reportError', e)
  }
}
</script>
