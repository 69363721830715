import { createApp, setup } from '@/setup'
import 'vite/modulepreload-polyfill'

import Router from '@/modules/Configurator/Router'
import Configurator from '@/modules/Configurator/components/Configurator'

const element = document.getElementById('configurator')

const props = element.dataset

const app = setup(createApp(Configurator, props))
app.use(Router)
app.mount(element)
