<template>
  <sanitas-survey-web :base-url="baseUrl" />
</template>

<script setup>
import { SanitasSurveyWeb } from 'sanitas-survey'

// INIT
const baseUrl = `${import.meta.env.VITE_SANITAS_MICROSERVICE_URI}survey`
window.Sanitas = window.Sanitas || {}
window.Sanitas.survey = window.Sanitas.survey || {}
</script>
