<template>
  <div
    v-show="messages.length"
    ref="systemNotificationsRef"
    class="system__notifications"
  >
    <basic-message
      v-for="message in messages"
      :key="`${message.title}${message.text}`"
      class="messages__message"
      :closable="false"
      :severity="getSeverity(message.level)"
      :title-label="message.text"
    />
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'

import { BasicMessage } from '@/components/Basic'

// INIT
const props = defineProps({
  language: {
    type: String,
    required: true,
  },
  baseUrl: {
    type: String,
    required: true,
  },
  platform: {
    type: String,
    default: 'others',
  },
  fetchMessages: {
    type: Function,
    default: payload => {
      if (window.fetch) {
        return window.fetch(`${payload.serviceUri}?platform=${payload.platform}`).then(response => response.json())
      } else {
        console.error("Can't fetch notifications from" + payload.serviceUri)
      }
    },
  },
})

// DATA
const messages = ref([])

// COMPUTED
const serviceUri = computed(() => {
  const languageLowerCase = props.language.toLowerCase()
  return `${props.baseUrl}bin/sanitas-system-notification.${languageLowerCase}.dvp.json`
})

// METHODS
function call() {
  const payload = {
    serviceUri: serviceUri.value,
    platform: props.platform,
  }
  props
    .fetchMessages(payload)
    .then(response => {
      if (response && response.notifications) {
        messages.value = response.notifications
      }
    })
    .catch(error => console.error(error))
}

function getSeverity(level) {
  switch (level) {
    default:
    case 'INFORMATION':
      return 'info'

    case 'ERROR':
      return 'error'

    case 'WARNING':
      return 'warning'

    case 'SUCCESS':
      return 'success'
  }
}

// LIFECYCLE HOOKS
onMounted(() => {
  call()
})
</script>

<style scoped>
.system__notifications {
  position: relative;
  top: 0;
  width: 100%;
  z-index: var(--dvp-stack-level-system-notification);
  transition: position 0.75s ease-in-out;
}
.message {
  border-radius: 0;
}

@media (--v-medium) {
  .system__notifications {
    position: sticky;
  }
  .section__header {
    padding-top: 0;
  }
}
</style>
