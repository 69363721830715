<template>
  <basic-modal
    id="appointmentModal"
    ref="appointmentModalRef"
    type="passive"
  >
    <div class="callback">
      <iframe
        id="calenso"
        name="calenso-widget"
        referrerpolicy="strict-origin"
        sandbox="allow-scripts allow-same-origin allow-storage-access-by-user-activation"
        :src="src"
      />
    </div>
  </basic-modal>
</template>

<script setup>
import { onBeforeMount, onBeforeUnmount, ref } from 'vue'

import useI18n from '@/hooks/useI18n'

import { events$ } from '@/services'

import { BasicModal } from '@/components/Basic'

import { EVENT_APPOINTMENT_MODAL_OPEN } from '@/config/events'

// HOOKS
const { t } = useI18n()

// DATA
const src = ref('')
const appointmentModalRef = ref()

// METHODS
function open(param) {
  src.value = t(`application.sanitas.calenso.${param}`)
  appointmentModalRef.value.open()
}

// LIFECYCLE HOOKS
onBeforeMount(() => {
  events$.on(EVENT_APPOINTMENT_MODAL_OPEN, open)
})
onBeforeUnmount(() => {
  events$.off(EVENT_APPOINTMENT_MODAL_OPEN, open)
})
</script>

<style name="mobile" scoped>
.callback {
  padding: 50px 0 0 0;
}

#calenso {
  min-height: 500px;
  height: 100%;
  width: 100%;
  border: none;
}
</style>
